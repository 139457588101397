<template>
    <v-card>
        <v-app-bar dense>
            <v-toolbar-title>
                Currencies
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click.prevent="currencyDialog = true"
                           slot="activator"
                           text
                           fab
                           small
                           right>
                        <v-icon>fas fa-plus</v-icon>
                    </v-btn>
                </template>
                <span>Add Currency</span>
            </v-tooltip>
        </v-app-bar>

        <v-card-text>

            <currencyList v-for="item in currency" :key="item.id" v-bind:liItem="item"></currencyList>

        </v-card-text>


        <!--Add Currency Modal-->
        <v-layout row justify-center>
            <v-dialog v-model="currencyDialog" persistent max-width="450px">
                <v-card>
                    <v-toolbar flat>
                        <v-toolbar-title>
                            Add Currency
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click.native="addNewCurrency($v)">
                            <v-icon color="green">fas fa-check</v-icon>
                        </v-btn>
                        <v-btn icon @click.native="currencyDialog = false; $v.$reset()">
                            <v-icon color="red">fas fa-times</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-autocomplete :items="allCurrencies"
                                                    v-model="newCurrency.currencyName"
                                                    label="Currency Name"
                                                    item-text="currencyName"
                                                    item-value="currencyName"
                                                    chips
                                                    :error-messages="currencyName"
                                                    @input="$v.newCurrency.currencyName.$touch()"
                                                    @blur="$v.newCurrency.currencyName.$touch()">
                                        <template slot="selection" slot-scope="data">
                                            <v-chip @input="data.parent.selectItem(data.item)"
                                                    :input-value="data.selected"
                                                    class="chip--select-multi"
                                                    :key="JSON.stringify(data.item)">

                                                {{ data.item.currencyName }}
                                            </v-chip>
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            <v-list-item-content>
                                                <v-list-item-title v-text="data.item.currencyName"></v-list-item-title>
                                                <v-list-item-subtitle v-text="data.item.currencyShort"></v-list-item-subtitle>
                                                <v-divider></v-divider>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field label="Currency Short" placeholder="-- -- --"
                                                  v-mask="'AAA'"
                                                  v-model="newCurrency.currencyShort"
                                                  disabled
                                                  :error-messages="currencyShort"
                                                  @input="$v.newCurrency.currencyShort.$touch()"
                                                  @blur="$v.newCurrency.currencyShort.$touch()"></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field label="GCT"
                                                  v-model="newCurrency.GCT"
                                                  suffix="%"></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-layout>
        <!--End Of Add Currency Modal-->




    </v-card>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required, minLength } from 'vuelidate/lib/validators'

    import { mask } from 'vue-the-mask'

    import { globalFunctions } from '../../../javascript/globalFunctions'
    import currencyJson from '../../../json/currencies.json'

    
    export default {
        mixins: [validationMixin],
        components: {
            currencyList: () => import('../../../components/listItem/currencyList.vue')
        },
        directives: { mask },
        data() {
            return {
                saveOptions: false,
                editOptions: true,
                sameCurrency: false,
                sameCurrency: false,
                sameCurrencyShort: false,
                currencyDialog: false,
                gct: '',
                savedGCT: [],
                currency: [],
                currencies: [],
                allCurrencies: [],
                newCurrency: {
                    id: 0,
                    currencyName: '',
                    currencyShort: '',
                    gctId: 0,
                    GCT: '',
                    objectState: 'New'
                },
            }
        },
        computed: {
            currencyName() {
                const errors = []
                if (!this.$v.newCurrency.currencyName.$dirty) return errors
                this.sameCurrency && errors.push('A Currency already has this Name. Select a new Name or update Currency')
                !this.$v.newCurrency.currencyName.required && errors.push('Currency Name is required')
                return errors
            },
            currencyShort() {
                const errors = []
                if (!this.$v.newCurrency.currencyShort.$dirty) return errors
                this.sameCurrencyShort && errors.push('A Currency already has this Name. Enter a new Name or update Currency')
                !this.$v.newCurrency.currencyShort.required && errors.push('Currency Short is required')
                !this.$v.newCurrency.currencyShort.minLength && errors.push('Currency Short needs 3 characters')
                return errors
            }
        },
        methods: {
            newCurrencyCheck: function ($v) {
                $v.$touch(); //Triggers Error Messages

                if ($v.newCurrency.currencyName.$invalid || $v.newCurrency.currencyShort.$invalid) {
                    return false
                }

                for (let i = 0; i < this.currencies.length; i++) {
                    if (this.currencies[i].currencyName.toLowerCase() === this.newCurrency.currencyName.toLowerCase()) {
                        this.sameCurrency = true
                        return
                    }
                    if (i === this.currencies.length - 1) {
                        this.sameCurrency = false
                        this.sameCurrencyShort = false
                    }
                }

                return true
            },
            addNewCurrency: function ($v) {
                if (this.newCurrencyCheck($v)) {
                    this.snackbar = true
                    this.snackbarMessage = "Currency Added"
                    this.currencyDialog = false

                    let newEntry = JSON.parse(JSON.stringify(this.newCurrency))

                    newEntry.id = globalFunctions.getNextIdForObject(this.currencies)

                    this.$store.commit('addNewCurrencyToStore', newEntry)
                    this.clearNewCurrency($v)
                    this.getAvailableCurriencies()
                }
            },
            clearNewCurrency: function ($v) {
                this.newCurrency.currencyName = ''
                this.newCurrency.currencyShort = ''

                $v.$reset()
            },
            getAvailableCurriencies: function () {
                this.currencies = this.$store.state.Currencies

                try {
                    if (this.currency.length > 0) {
                        this.currency = []
                    }
                }
                catch{}

                for (let i = 0; i < this.currencies.length; i++) {
                    if (this.currencies[i].ObjectState !== "Delete") {
                        this.currency.push(this.currencies[i])
                    }
                }
            },
            remove: function (item) {
                const index = this.currency.indexOf(item.currencyName)
                if (index >= 0) this.currency.splice(index, 1)
            },
            returnSelectedCurrency: function (currency) {
                return currency.currencyName === this.newCurrency.currencyName
            }
        },
        watch: {
            "newCurrency.currencyName": function () {
                this.newCurrency.currencyShort =
                    this.allCurrencies.find(this.returnSelectedCurrency).currencyShort
            }
        },
        beforeMount() {
            this.getAvailableCurriencies()

            this.savedGCT = JSON.parse(JSON.stringify(this.$store.state.GCT))

            this.allCurrencies = currencyJson
        },
        validations: {
            newCurrency: {
                currencyName: {
                    required
                },
                currencyShort: {
                    required,
                    minLength: minLength(3)
                }
            }
        }
    }

</script>